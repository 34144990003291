<!-- 考试模板-->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";

/*  .edui-mask.edui-dialog-modalmask.edui-default{
    z-index: 7777 !important;
  } */
#edui_fixedlayer {
  z-index: 2000 !important;
}

#previewModal > .modal-dialog {
  max-width: 1200px !important;
  width: 1200px !important;
}

#previewModal p {
  margin-bottom: 0 !important;
}

.oldValue p {
  margin-bottom: 0 !important;
}


.oldValue {
  position: absolute;
  z-index: -99999;
  visibility: hidden;
}
</style>
<style type="text/css" scoped>
/deep/ .el-form-item__content {
  line-height: inherit;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination__jump {
  margin-left: 0;
  margin-right: 10px;
}

.check-distri span {
  width: 72px;
  display: inline-block;
}

/deep/#edui_fixedlayer {
  z-index: 2000 !important;
}

.content-mould {
  width: 100%;
  padding: 5px;
  height: 400px;
  overflow-y: auto;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import BaseHeader from "@/components/base-header";
import $ from "jquery";
import {
  getNoticeList,
  updateNoticeTemplateQyzt,
  deleteNoticeTemplate,
  addNoticeTemplate,
  editNoticeTemplate,
  getNoticeDetails,
} from "@/api/admin/base/mould.js";
import { SecondEasyList } from "@/api/admin/base/mainType.js";
import { changeUrl } from "@/api/common/public.js";
import { startLoading, endLoading } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    BaseHeader,
  },
  data() {
    return {
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "控制面板",
          active: true,
        },
      ],
      checked: true,
      exportTitle: "提示模板导出",
      importTitle: "提示模板导入",
      defaultvalue: 3,
      currentpage: 1,
      showmodal: false,
      showmodal2: false,
      previewModal: false,
      previewData: {},
      tableList: [],
      dialogTitle: "添加协议、提示模板",
      module: "KSTSMB",
      classList: [],
      addForm: {},
      pageData: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      secondList: [],
      editor: "",
      moduleType: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
    };
  },
  methods: {
    // 删除
    deleteItem(sid, text) {
      this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteNoticeTemplate(sid).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "数据删除成功!",
              });
            }
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取数据列表
    getList() {
      getNoticeList(this.pageData).then((res) => {
        if (res.status) {
          this.tableList = res.data;
          this.pageData.total = res.total;
        }
      });
    },
    // 改变启用状态
    changeQyzt(obj) {
      let { sid, qyzt } = obj;
      updateNoticeTemplateQyzt({
        sid: sid,
        qyzt: qyzt,
      }).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "状态修改成功！",
          });
        }
      });
    },
    // 添加类别
    addMaintype() {
      let formDate = JSON.parse(JSON.stringify(this.addForm));
      if (formDate.glfs == "无") {
        formDate.gllddm = "";
      }
      addNoticeTemplate(formDate).then((res) => {
        if (res.status) {
          this.success();
        }
      });
    },
    editMaintype() {
      let formDate = JSON.parse(JSON.stringify(this.addForm));
      formDate.sid = this.addForm.sid;

      if (formDate.glfs != "无") {
        if (!formDate.gllbdm) {
          this.$notify({
            type: "warning",
            message: "请选择所属类别!",
            duration: 3000,
          });
          return;
        }
      }
      editNoticeTemplate(formDate).then((res) => {
        if (res.status) {
          this.success();
        }
      });
    },
    success() {
      this.$notify({
        type: "success",
        message: "数据操作成功!",
        duration: 3000,
      });
      this.addForm = {
        mblb: "",
        glfs: "",
        gllbdm: "",
        gllbmc: "",
        mbmc: "",
        qyzt: true,
        mbnr: "",
      };
      this.showmodal = false;
      this.getList();
    },
    addModal() {
      this.showmodal = true;
      this.dialogTitle = "添加协议、提示模板";
      this.addForm = {
        mblb: "",
        glfs: "",
        gllbdm: "",
        gllbmc: "",
        mbmc: "",
        qyzt: true,
        mbnr: "",
      };
      this.getBm(this.module, "mb").then((e) => {
        this.addForm.mbbm = e;
      });
    },
    editItem(data) {
      this.showmodal = true;
      this.dialogTitle = "修改协议、提示模板";
      this.addForm = JSON.parse(JSON.stringify(data));
      getNoticeDetails(data.mbbm).then((res) => {
        if (res.status) {
          this.addForm.mbnr = res.data.mbnr;
        }
      });
    },
    open(data) {
      this.$confirm("横版预览还是竖版预览", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "竖版",
        cancelButtonText: "横版",
      })
        .then(() => {
          this.moduleType = 0;
          this.pageHeight = 1103;
          this.showItem(data);
        })
        .catch((action) => {
          if (action === "cancel") {
            this.moduleType = 1;
            this.pageHeight = 780;
            this.showItem(data);
          }
        });
    },
    showItem(data) {
      getNoticeDetails(data.mbbm).then((res) => {
        if (res.status) {
          this.previewData = res.data;
          this.previewModal = true;
          setTimeout(() => {
            this.toPdfHtml("previewContent", "pdf", "oldValue");
          }, 1000);
        }
      });
    },
    // 处理百度编辑器里面的图片，转为base64
    imgUrlToBase64() {
      var data = this.editor.getContent();
      var urls = [];
      $(data)
        .find("img")
        .each((x, y) => urls.push(y.src));

      changeUrl(urls);
    },
    submit() {
      this.imgUrlToBase64();
      if (this.addForm.sid) {
        this.editMaintype();
      } else {
        this.addMaintype();
      }
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getList();
    },

    // 二级分类
    getSecondList() {
      SecondEasyList().then((res) => {
        if (res.status) {
          this.secondList = res.data;
        }
      });
    },
    getgllbmc() {
      let val = this.addForm.gllbdm;
      let obj = this.secondList.find((k) => {
        return k.flbm === val;
      });
      this.addForm.gllbmc = obj.flmc;
    },
    uploadSuccess() {
      // 数据导入之后刷新列表
      this.getList();
    },

    ready(instance) {
      this.editor = instance; // 保存vue-ueditor-wrap实例
    },
    pageSign() {
      let height = $(".outerCotent").height();
      this.pageNum = parseInt(height / this.pageHeight);
    },
    toPdfHtml(id, id1, id2, zsAdd) {
      if ($("#" + id1).length > 0) {
        return false;
      }
      this.pdfNum = 1;
      this.pdfHeight = 0;
      $("#" + id).append("<div id='" + id1 + "'></div>");
      $("#" + id1).append(
        "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
      );
      // 考试规则
      $("#" + id2 + " .gzContent").each((i, item) => {
        // 标题
        $(item)
          .children("p")
          .each((i, item1) => {
            this.addItem(item1, $(item1).outerHeight(), id1);
          });
        // 内容
        $(item)
          .find(".contentMain")
          .children()
          .each((i, item1) => {
            $(item1).css("width", "unset");
            this.addItem(item1, $(item1).outerHeight(), id1);
          });
      });
      // 准考证内容
      if (zsAdd) {
        let zsItem = $("#" + id2 + " .zsContent")[0];
        this.addItem(zsItem, $(zsItem).outerHeight(), id1, this.imgUrl);
      }
    },
    addItem(item, height, id, imgurl) {
      let paddingHeight = 84;
      height = Number(height);
      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#" + id).append(
          "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
        );
      }
      if (imgurl) {
        $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
      }
      this.pdfHeight += height;
      // 将内容放入div中
      $(item)
        .clone()
        .appendTo($("#" + id + this.pdfNum));
      // $("#" + id + " .pdf").css("border", "1px #000000 solid");
      $("#" + id + " .pdf").css("background", "white");
      // $("#" + id + " .pdf").css("padding", "126px 93px 97px");
      $("#" + id + " .pdf").css("padding", "56px 70px 28px");
      $("#" + id + " .pdf").css("margin-bottom", "20px");
      $("#" + id + " .pdf").css("color", "black");
    },
  },
  mounted() {
    this.getList();
    this.getSecondList();
    this.UEditorConfig.initialFrameHeight = 350; //定义富文本高度
  },
};
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <b-button
                  @click="addModal"
                  variant="outline-info"
                  class="flexList mr-2 condition"
                  ><i class="iconfont icon-plus-circle1 mr-2 font-size-20"></i
                  >添加模板</b-button
                >
                <el-button
                  size="small"
                  type="primary"
                  plain
                  class="flexList mr-2 h30"
                  @click="
                    $importModal().show({
                      title: importTitle,
                      module: module,
                      success: uploadSuccess,
                    })
                  "
                  ><i class="iconfont icon-riLine-upload-2-line mr-2"></i
                  >{{ importTitle }}</el-button
                >
              </div>
              <div class="d-flex">
                <div
                  class="border-blue export-tab"
                  @click="
                    $exportModal().show({
                      title: exportTitle,
                      type: 'xlsx',
                      module: module,
                    })
                  "
                >
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div
                  class="border-blue export-tab"
                  @click="
                    $exportModal().show({
                      title: exportTitle,
                      type: 'dbf',
                      module: module,
                    })
                  "
                >
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th style="width: 7%">模板编码</th>
                    <th>模板名称</th>
                    <th style="width: 8%">模板类型</th>
                    <th style="width: 20%">关联类别</th>
                    <th style="width: 8%">是否启用</th>
                    <th style="width: 6%">修改人</th>
                    <th style="width: 10%">修改时间</th>
                    <th style="width: 6%">预览</th>
                    <th style="width: 4%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in tableList" :key="index">
                    <td>
                      {{
                        index + 1 + (pageData.pageNum - 1) * pageData.pageSize
                      }}
                    </td>
                    <td>{{ obj.mbbm }}</td>
                    <td>
                      <div class="font-blue" @click="editItem(obj)">
                        {{ obj.mbmc }}
                      </div>
                    </td>
                    <td>{{ obj.mblb }}</td>
                    <td>{{ obj.gllbmc }}</td>
                    <td>
                      <b-form-checkbox
                        v-model="obj.qyzt"
                        switch
                        class="switch-check"
                        @change="changeQyzt(obj)"
                      >
                      </b-form-checkbox>
                    </td>

                    <td>{{ obj.modifyUser }}</td>
                    <td>{{ timeSlice(obj.modifyTime) }}</td>
                    <td>
                      <i
                        class="iconfont icon-graphic align-middle mr-1"
                        @click="open(obj)"
                      ></i>
                    </td>
                    <td class="tab-icon">
                      <i
                        class="iconfont icon-edit-two align-middle mr-1"
                        @click="editItem(obj)"
                      ></i
                      ><i
                        class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid, obj.mbmc)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination
                @size-change="handleSizeChange"
                background
                @current-change="handleCurrentChange"
                :current-page="pageData.pageNum"
                :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next"
                :total="pageData.total"
              >
              </el-pagination>
            </div>

            <div class="oldValue">
              <el-row
                class="zkzcnsList container"
                :style="{ width: moduleType == '1' ? '1103px' : '780px' }"
                style="padding: 56px 70px 28px"
                id="oldValue"
              >
                <div class="w100">
                  <div class="gzContent nobgColor">
                    <div
                      v-html="previewData.mbnr"
                      class="contentMain"
                      style="font-family: 'Nunito', sans-serif"
                    ></div>
                  </div>
                </div>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <b-modal
      id="addMould"
      v-model="showmodal"
      centered
      :title="this.dialogTitle"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div>
        <el-form ref="addForm" :model="addForm">
          <div class="grey-time mb-3">
            说明：模板添加后在报名设置时可以选用。
          </div>
          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>模板类型：</span>
              <div class="col-sm-10 p-0 flexList">
                <select
                  name=""
                  class="form-control form-select w-100"
                  v-model="addForm.mblb"
                >
                  <option value="" disabled>请选择模板类型</option>
                  <option value="报名协议">报名协议</option>
                  <option value="承诺书">承诺书</option>
                  <option value="注意事项">注意事项</option>
                  <option value="准考证">准考证(规则)</option>
                  <option value="准考证告知承诺">准考证(告知承诺)</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3" v-if="addForm.mblb == '其他'">
              <span>模板类别：</span>
              <div class="col-sm-10 p-0 flexList">
                <input
                  type="text"
                  name=""
                  id=""
                  maxlength="10"
                  value=""
                  v-model="addForm.mblb"
                  placeholder="请输入其他模板类型"
                  class="form-control w-100 h30"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>分类关联：</span>
              <div class="col-sm-10 p-0 flexList">
                <select
                  name=""
                  class="form-control form-select w-45"
                  v-model="addForm.glfs"
                >
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option value="二级类别">二级类别</option>
                  <option value="无">无</option>
                </select>
                <span class="ml-2">所属类别：</span>
                <select
                  name=""
                  class="form-control form-select w-45"
                  v-model="addForm.gllbdm"
                  @change="getgllbmc"
                  v-if="addForm.glfs != '无'"
                >
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option
                    :value="item.flbm"
                    v-for="(item, index) in secondList"
                    :key="index"
                  >
                    {{ item.flmc }}
                  </option>
                </select>
                <select
                  name=""
                  class="form-control form-select w-45"
                  v-model="addForm.gllbdm"
                  v-else
                >
                  <option value="" disabled>所属考试类别，非必须</option>
                  <option value="无">无</option>
                </select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3">
              <span>模板标题:</span>
              <div class="col-sm-10 p-0 flexList">
                <input
                  type="text"
                  name=""
                  id=""
                  maxlength="50"
                  value=""
                  v-model="addForm.mbmc"
                  placeholder="请输入标题"
                  class="form-control w-100 h30"
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex mb-3 w-95">
              <div class="content-mould">
                <!-- <ckeditor :editor="editor" v-model="addForm.mbnr" ></ckeditor> -->
                <vue-ueditor-wrap
                  @ready="ready"
                  v-model="addForm.mbnr"
                  :config="UEditorConfig"
                >
                </vue-ueditor-wrap>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3">
              <label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15">
                  <input
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="true"
                    v-model="addForm.qyzt"
                    class="form-check-input"
                  /><label for="exampleRadios1" class="form-check-label"
                    >启用
                  </label>
                </div>
                <div class="form-check mr-2">
                  <input
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="fasle"
                    v-model="addForm.qyzt"
                    class="form-check-input"
                  /><label for="exampleRadio1" class="form-check-label"
                    >不启用</label
                  >
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div class="mt-3 text-center">
        <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="submit"
        >
          确定
        </button>
        <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showmodal = false"
        >
          取消
        </button>
      </div>
    </b-modal>

    <!-- 弹窗结束 -->
    <!-- 预览弹窗开始 -->
    <b-modal
      id="previewModal"
      v-model="previewModal"
      centered
      title="模板预览"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div
        style="background: rgb(171, 171, 171); padding: 10px"
        class="d-flex justify-content-center"
      >
        <div
          id="previewContent"
          class="white-bg"
          :style="{ width: moduleType == '1' ? '1103px' : '780px' }"
        ></div>
      </div>
      <!-- <div class="mt-3 text-center">
        <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="submit"
        >
          确定
        </button>
        <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showmodal = false"
        >
          取消
        </button>
      </div> -->
    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
